"use client"

import type { TrackingPageViewProps } from "@/modules/tracking/types"
import type { EnabledFlag, UnleashContext } from "@/modules/unleash/types"
import type { CurrentUser } from "@/modules/user/api/types"
import type { RudderstackMod } from "@jobteaser/js-tracking"
import type { FunctionComponent, PropsWithChildren } from "react"

import { usePathname } from "next/navigation"
import { useCallback, useEffect, useState } from "react"

import { getCookie } from "@/modules/client/cookies/getCookies"
import { setCookie } from "@/modules/client/cookies/setCookie"
import { useLocale } from "@/modules/locales/useLocale"
import { useHref } from "@/modules/routing/hooks/useHref"
import { initTracking } from "@/modules/tracking/initTracking"
import { TrackingLibContext } from "@/modules/tracking/useTracking"
import { getExperimentInfosFromCookie } from "@/modules/unleash/getExperimentInfosFromCookie"

const EXPERIMENT_INFO_COOKIE_NAME = "jt_unleash_experiment_info"

type TrackingAppProviderProps = PropsWithChildren<{
  activeFlags: EnabledFlag[]
  currentUser?: CurrentUser | undefined
  pageViewProps?: TrackingPageViewProps
  unleashContext: UnleashContext
}>

export const TrackingProvider: FunctionComponent<TrackingAppProviderProps> = ({
  activeFlags,
  children,
  currentUser,
  pageViewProps = {},
  unleashContext,
}) => {
  const href = useHref()
  const locale = useLocale()
  const pathname = usePathname()
  const [tracking, setTracking] = useState<RudderstackMod | null>(null)
  const [currentPathname, setCurrentPathname] = useState<string | null>(null)

  const sendPageView = useCallback(() => {
    if (tracking && pathname !== currentPathname) {
      setCurrentPathname(pathname)

      tracking.page("page_view_next", {
        ...pageViewProps,
        path: pathname,
        url: `${window.location.origin}${href}`,
      })
    }
  }, [tracking, pageViewProps, href, pathname, currentPathname])

  useEffect(() => {
    ;(typeof window.requestIdleCallback !== "undefined" ? window.requestIdleCallback : setTimeout)(() => {
      initTracking(locale, unleashContext, setTracking)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    sendPageView()
  }, [pathname, sendPageView])

  useEffect(() => {
    if (currentUser) {
      // Waiting for tracking client (RudderStack) to be available
      if (!tracking) {
        return
      }

      const syncUserInfo = (): void => {
        const experimentInfos = activeFlags.map(({ name, variant }) => `${name}_${variant || "enabled"}`)

        // Note that the cookie is NOT shared with "ui-jobteaser" (spa pages)
        const savedExperimentInfos = getExperimentInfosFromCookie(getCookie(EXPERIMENT_INFO_COOKIE_NAME))

        // We should sync user info to Rudderstack if the unleash flags have changed
        // or if the user id registered in Rudderstack is different from the current user id
        const shouldBeSynced =
          JSON.stringify(experimentInfos) !== JSON.stringify(savedExperimentInfos) ||
          tracking.getUserId() !== currentUser.attributes.uuid

        // Updating the cookie anyway so the expiration date is updated too
        setCookie({ name: EXPERIMENT_INFO_COOKIE_NAME, path: "/", value: window.btoa(experimentInfos.toString()) })

        if (shouldBeSynced) {
          tracking.identify({ unleash_experiment_info: experimentInfos }, currentUser.attributes.uuid || null)
        }
      }

      syncUserInfo()

      tracking.start()
    }
  }, [currentUser, tracking, activeFlags])

  return <TrackingLibContext.Provider value={tracking}>{children}</TrackingLibContext.Provider>
}
