import { createParseError, createRequestError, ensureError } from "@/modules/fetch/errorHandler"

type GetResultFromResponseOptions<T> = {
  onHttpRedirect?: () => T
  transformResult?: (result: unknown) => T
  useAPIErrorResponse?: boolean
}

/**
 * When useAPIErrorResponse is true, the helper will set the error cause with the response body.
 * Note that if the response is not a valid JSON, we create a JSON object with the text of the response.
 */
export const handleResponseNotOk = async (response: Response, useAPIErrorResponse: boolean = false): Promise<void> => {
  if (response.ok) return

  if (!useAPIErrorResponse) {
    throw createRequestError(response)
  }

  let body
  try {
    body = await response.clone().json()
  } catch (_) {
    // do nothing
  }

  if (!body) {
    try {
      const text = await response.clone().text()
      if (text) {
        body = { error: text }
      }
    } catch (_) {
      // do nothing
    }
  }

  if (!body) {
    body = { error: response.statusText }
  }

  throw createRequestError(response, body)
}

export const getResultFromResponse =
  <T>(options?: GetResultFromResponseOptions<T>) =>
  async (response: Response): Promise<T> => {
    const { useAPIErrorResponse = false } = options ?? {}

    await handleResponseNotOk(response, useAPIErrorResponse)

    if (response.status === 300 && options?.onHttpRedirect) {
      return options.onHttpRedirect()
    }

    const responseText = await response.clone().text()

    try {
      if (options?.transformResult) {
        return options.transformResult(await response.json())
      }

      return (await response.json()) as T
    } catch (error) {
      throw createParseError(responseText, ensureError(error))
    }
  }
