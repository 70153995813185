/* eslint-disable max-classes-per-file */

import { logger } from "@/modules/monitoring/logger"

export class RequestError extends Error {
  public constructor(public status: number, message?: string) {
    super(message)
  }
}

class ParseError extends Error {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ensureError(err: Error | string | any): Error {
  if (err instanceof Error) {
    return err
  }
  if (typeof err === "string") {
    return new Error(err)
  }
  return new Error("Unknown error")
}

export function createParseError(json: string, error: Error): ParseError {
  const parseError = new ParseError(`${error.message} with "${json}"`)
  parseError.name = "ParseError"
  return parseError
}

export function createRequestError(response: Response, body?: unknown): RequestError {
  const requestError = new RequestError(response.status)
  requestError.name = "RequestError"
  requestError.cause = body
  return requestError
}

function formatFetchError<APIErrorResponse = void>(error: Error, endpoint: string, context?: object): Error {
  let formattedError: Error
  if (error.name === "TimeoutError") {
    const message = `${error.name} occurred when fetching endpoint "${endpoint}"`
    const messageSuffix = context && `timeout` in context ? `: the request took longer than ${context.timeout}ms` : ""
    const providedContext = context ? ` (${JSON.stringify(context)})` : ""

    formattedError = new Error(`${message}${messageSuffix}${providedContext}`)
  } else if (error instanceof RequestError) {
    formattedError = new Error(
      `${error.name} occurred when fetching endpoint "${endpoint}": ${error.status}${
        context ? ` (${JSON.stringify(context)})` : ""
      }`,
      { cause: error.cause as APIErrorResponse }
    )
  } else {
    formattedError = new Error(
      `${error.name} occurred when fetching endpoint "${endpoint}": ${error.message}${
        context ? ` (${JSON.stringify(context)})` : ""
      }`
    )
  }

  formattedError.name = error.name

  return formattedError
}

type LogOptions = boolean | ("TimeoutError" | "RequestError" | "ParseError" | "Error")[]

type Options = {
  log?: LogOptions
}

export function handleFetchError<APIErrorResponse = void>(endpoint: string, context?: object, options?: Options) {
  return (error: Error) => {
    const formattedError = formatFetchError<APIErrorResponse>(error, endpoint, context)

    if (!options) {
      throw Error(formattedError.message, formattedError.cause ? { cause: formattedError.cause } : undefined)
    }

    const { log } = options

    const shouldLogAllErrors = log === true
    const shouldLogThisSpecificErrorType =
      Array.isArray(log) && log.includes(formattedError.name as (typeof log)[number])

    if (shouldLogAllErrors || shouldLogThisSpecificErrorType) {
      logger.error(formattedError)
    }

    throw Error(formattedError.message, formattedError.cause ? { cause: formattedError.cause } : undefined)
  }
}
