import type { I18nLocale } from "@/modules/i18n/types"
import type { Location } from "@/modules/locations/locations"
import type {
  currentUserCurriculumIdsSchema,
  currentUserResponseSchema,
  currentUserRoleSchema,
  currentUserSchema,
  studentRoleSchema,
} from "@/modules/user/api/schemas"
import type * as v from "valibot"

export const DEFAULT_USER_ROLE = ["anonymous"] as const

export const STUDENT_ROLES = ["student", "student_recruiter", "staff_user"] as const

export const JT_ADMIN_ROLES = ["jt_admin", "jt_super_admin", "jt_dev_admin", "jt_support_admin"] as const

export const SCHOOL_ADMIN_ROLES = [
  "cc_admin",
  "cc_super_admin",
  "cc_content_manager",
  "cc_career_advisor_admin",
  "cc_companies_content_manager",
  "cc_school_content_manager",
  "cc_career_advisor",
  "cc_company_relationship_officer",
] as const

export const COMPANY_ADMIN_ROLES = [
  "company_admin",
  "company_offers_manager",
  "company_super_admin",
  "company_talent_bank_user",
  "company_campus_manager",
  "company_talent_ambassador",
] as const

// public_recruiter is the role for private recruiters, don't ask me why
export const RECRUITER_ADMIN_ROLES = ["public_recruiter", "company_school_admin"] as const

export const USER_ADMIN_ROLES = [...JT_ADMIN_ROLES, ...SCHOOL_ADMIN_ROLES, ...COMPANY_ADMIN_ROLES] as const

// Users eligible to preview a draft JobAd
export const USER_ROLES_ELIGIBLE_TO_PREVIEW_DRAFT_JOBADS = [...RECRUITER_ADMIN_ROLES, ...USER_ADMIN_ROLES] as const

// Users eligible to apply to a JobAd
export const USER_ROLES_ELIGIBLE_TO_APPLY = [
  ...STUDENT_ROLES,
  "cc_admin",
  "cc_super_admin",
  "cc_content_manager",
  "cc_career_advisor",
  "cc_school_content_manager",
  "cc_company_relationship_officer",
  "cc_companies_content_manager",
] as const

// Users NOT eligible to apply to a JobAd
export const USER_ROLES_NOT_ELIGIBLE_TO_APPLY = [
  ...DEFAULT_USER_ROLE,
  ...STUDENT_ROLES,
  ...JT_ADMIN_ROLES,
  ...SCHOOL_ADMIN_ROLES,
  ...COMPANY_ADMIN_ROLES,
  ...RECRUITER_ADMIN_ROLES,
  ...USER_ADMIN_ROLES,
].filter(role => !USER_ROLES_ELIGIBLE_TO_APPLY.includes(role))

// Users NOT eligible to see Onboarding
export const USER_ROLES_NOT_ELIGIBLE_TO_ONBOARDING = [...SCHOOL_ADMIN_ROLES]

export const USER_ROLES_ELIGIBLE_TO_UNI_TALENTS = ["cc_admin", "cc_super_admin", "cc_career_advisor_admin"] as const

export type StudentRoles = v.InferInput<typeof studentRoleSchema>

export type CurrentUserRole = v.InferInput<typeof currentUserRoleSchema>

export type UserAdminRoles = (typeof USER_ADMIN_ROLES)[number]

export type UserRolesEligibleToApply = (typeof USER_ROLES_ELIGIBLE_TO_APPLY)[number]

export type CurrentUserCurriculumIds = v.InferInput<typeof currentUserCurriculumIdsSchema>

export const ONBOARDING_ELIGIBLE_STATUSES = ["ELIGIBLE_STUDENT_FIRST_TIME", "ELIGIBLE_STUDENT_RETURNING"] as const

const ONBOARDING_INELIGIBLE_STATUSES = [
  "INELIGIBLE_ADMIN_HIDDEN",
  "INELIGIBLE_STUDENT_WITH_NO_PROFILE",
  "INELIGIBLE_USER",
] as const

export const ONBOARDING_STATUSES = [...ONBOARDING_ELIGIBLE_STATUSES, ...ONBOARDING_INELIGIBLE_STATUSES] as const

export type CurrentUser = v.InferInput<typeof currentUserSchema>

export type CurrentUserResponse = v.InferInput<typeof currentUserResponseSchema>

export type SearchConfigFilterOption = {
  label: string
  name: string
}

export type SearchConfigFilterOptionWithChildren = {
  children?: SearchConfigFilterOption[]
  label: string
  name: string
}

export type SearchConfigFilter = {
  label: string
  name: string
  options?: SearchConfigFilterOptionWithChildren[]
}
export type CurrentUserSearchConfig = {
  searchFilters: SearchConfigFilter[]
  negativeOptions: { studyLevels?: number[] }
  enabledFilters: { enabled: boolean; name: string }[]
}

export type CurrentUserSearchCriteria = {
  abroadOnly: boolean
  alumni: boolean
  companyBusinessTypes: string[]
  companySectors: string[]
  contractDurations: string[]
  contractTypes: string[]
  curriculumIds: string[]
  followedIndustries: string[]
  locale: I18nLocale[]
  locations: Location[]
  occupationalCategories: string[]
  positionCategoryIds: string[]
  remoteTypes: string[]
  schoolId: string
  studyLevels: number[]
  tags: string[]
  workExperienceCodes: string[]
}

export type CurrentUserSearchCriteriaResponse = {
  searchCriteria: CurrentUserSearchCriteria
}
